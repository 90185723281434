import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  providers: [MessageService]
})
export class ToastComponent implements OnInit, OnDestroy {
  subscription: Subscription | any;
  constructor(private toastService: ToastService, private messageService: MessageService) { }
  ngOnInit() {
    this.subscription = this.toastService.$responseMessage.subscribe({
      next: (msg: string) => {
        if (msg) {
          this.showMessage(msg);
        }
      }
    });
  }
  showMessage(message: string) {
    this.messageService.add({ severity: this.toastService.severity, summary: this.toastService.title, detail: message });
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
