import { Injectable, ErrorHandler } from "@angular/core";
import { AppInsightsService } from "./app-insights.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler{
    constructor(private AppInsightsService: AppInsightsService) {
        super();
    }

    handleError(error: Error) {
        this.AppInsightsService.logException(error);
    }
}