export const environment = {
  production: false,
  apiUrl: 'https://qaapi.captaindata.org/',
  url: 'https://qa.captaindata.org/',
  authToken: '$authorize',
  MemberWindow : {
    OpenDateMonth: '08',
    OpenDateDay: '15',
    CloseDateMonth: '09',
    CloseDateDay: '15'
  },
  appInsights: {
    instrumentationKey: '8fb9cebd-3362-41a9-91ca-3a636deebbc2'
  }
};
