import { Component } from '@angular/core';
import { AppInsightsService } from './core/services/app-insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Captain-client';

  constructor(AppInsightsService: AppInsightsService){}
}
