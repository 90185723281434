import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ToastComponent } from './components/toast/toast.component';
import {ToastModule} from 'primeng/toast';
import { DndDirective } from './directives/dnd.directive';

@NgModule({
  declarations: [
    ToastComponent,
    DndDirective
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ToastModule
  ],
  exports: [ToastComponent]
})

export class CoreModule { }
