import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  responseMessage: string = '';
  $responseMessage = new BehaviorSubject<any>(this.responseMessage);
  severity: string = '';
  title: string = '';
  
  constructor() { }

  success(message: string, title?: string) {
    this.severity = 'success';
    this.title = title || 'Success';
    this.$responseMessage.next(message);
  }

  warn(message: string, title?: string) {
    this.severity = 'warn';
    this.title = title || 'Warning';
    this.$responseMessage.next(message);
  }

  error(message: string, title?: string) {
    this.severity = 'error';
    this.title = title || 'Error';
    this.$responseMessage.next(message);
  }

  info(message: string, title?: string) {
    this.severity = 'info';
    this.title = title || 'Info';
    this.$responseMessage.next(message);
  }
}
