import { FormsModule } from '@angular/forms';
import { MbscModule } from '@mobiscroll/angular';
/* --------------- Modules ------------------- */
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';

/* --------------- Components ------------------- */
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PathInterceptor } from './core/interceptors/path-interceptor.service';
import { AuthInterceptor } from './core/interceptors/auth-interceptor.service';
import { AppInsightsService } from './core/services/app-insights.service';
import { ErrorHandlerService } from './core/services/errorhandler.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    FormsModule,
    MbscModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    AppInsightsService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: PathInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
